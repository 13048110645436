import React from "react";
import theme from "theme";
import { Theme, Text, Strong, Section, Image, Box, Em } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Wheel Wizards
			</title>
			<meta name={"description"} content={"Там, де кожна поїздка – ідеальна поїздка"} />
			<meta property={"og:title"} content={"Wheel Wizards"} />
			<meta property={"og:description"} content={"Там, де кожна поїздка – ідеальна поїздка"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19:30:27.444Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19:30:27.444Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19:30:27.444Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19:30:27.444Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19:30:27.444Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19:30:27.444Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19:30:27.444Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text6" />
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="link1" />
			<Override slot="text5" />
			<Override slot="text1" />
			<Override slot="link" />
		</Components.Header>
		<Components.Header2 />
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text
				as="h2"
				font="--headline1"
				md-font="--headline2"
				margin="20px 0 0 0"
				text-align="center"
			>
				Наші послуги
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				У Wheel Wizards ми прагнемо надавати повний спектр велосипедних послуг, щоб задовольнити потреби кожного водія. Наші технічні спеціалісти оснащені знаннями та інструментами, щоб вирішити будь-яку проблему, пов’язану з велосипедом, гарантуючи, що ваша поїздка завжди буде в ідеальному стані. Від основного технічного обслуговування до спеціалізованого ремонту ми пишаємося тим, що пропонуємо послуги найвищого рівня, щоб ви були в безпеці та задоволені в дорозі.
				<br />
				<br />
				<br />
				<Strong>
					У Wheel Wizards ми пропонуємо різноманітні послуги, розроблені для задоволення унікальних потреб кожного велосипедиста. Ось деякі з наших найпопулярніших послуг:
				</Strong>
			</Text>
		</Section>
		<Section padding="0 0 100px 0" quarkly-title="List-2">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="34px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/66795e813f24780021f8255f/images/1-4.jpg?v=2024-06-25T07:45:48.834Z"
						display="block"
						margin="0px 0px 20px 0px"
						max-width="100%"
						max-height="300px"
						width="100%"
						height="100%"
						object-fit="cover"
						srcSet="https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/1-4.jpg?v=2024-06-25T07%3A45%3A48.834Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/1-4.jpg?v=2024-06-25T07%3A45%3A48.834Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/1-4.jpg?v=2024-06-25T07%3A45%3A48.834Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/1-4.jpg?v=2024-06-25T07%3A45%3A48.834Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/1-4.jpg?v=2024-06-25T07%3A45%3A48.834Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/1-4.jpg?v=2024-06-25T07%3A45%3A48.834Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/1-4.jpg?v=2024-06-25T07%3A45%3A48.834Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="--dark">
						Базове налаштування: Ретельна перевірка та налаштування основних компонентів вашого велосипеда для забезпечення безперебійної роботи.{"\n"}
						<br />
						{" "}Регулювання гальм: Точне налаштування гальм для оптимальної продуктивності та безпеки.{"\n"}
						<br />
						{" "}Регулювання передач: забезпечує плавне й точне перемикання передач для легкої їзди.{"\n"}
						<br />
						{" "}Накачування шин: Перевірка та регулювання тиску в шинах, щоб запобігти спусканням і покращити якість їзди.{"\n"}
						<br />
						{" "}Перевірка безпеки: комплексна перевірка безпеки для виявлення та вирішення потенційних проблем.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/66795e813f24780021f8255f/images/2-4.jpg?v=2024-06-25T07:45:48.822Z"
						display="block"
						margin="0px 0px 20px 0px"
						max-width="100%"
						max-height="300px"
						width="100%"
						height="100%"
						object-fit="cover"
						object-position="top"
						srcSet="https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/2-4.jpg?v=2024-06-25T07%3A45%3A48.822Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/2-4.jpg?v=2024-06-25T07%3A45%3A48.822Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/2-4.jpg?v=2024-06-25T07%3A45%3A48.822Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/2-4.jpg?v=2024-06-25T07%3A45%3A48.822Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/2-4.jpg?v=2024-06-25T07%3A45%3A48.822Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/2-4.jpg?v=2024-06-25T07%3A45%3A48.822Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/2-4.jpg?v=2024-06-25T07%3A45%3A48.822Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="--dark">
						Повний ремонт: Повне розбирання, очищення та повторне збирання вашого велосипеда, щоб повернути його до стану, як новий.{"\n"}
						<br />
						{" "}Глибоке очищення: Ретельне очищення всіх частин велосипеда для видалення бруду та бруду.{"\n"}
						<br />
						{" "}Змащення:  Належне змащення всіх рухомих частин для забезпечення безперебійної роботи.{"\n"}
						<br />
						{" "}Перевірка компонентів: Детальна перевірка кожного компонента для визначення зношеності.{"\n"}
						<br />
						{" "}Повторна збірка: Повторне збирання вашого велосипеда з точністю для оптимальної роботи.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/66795e813f24780021f8255f/images/2-1.jpg?v=2024-06-25T07:45:48.833Z"
						display="block"
						margin="0px 0px 20px 0px"
						max-width="100%"
						max-height="300px"
						object-fit="cover"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/2-1.jpg?v=2024-06-25T07%3A45%3A48.833Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/2-1.jpg?v=2024-06-25T07%3A45%3A48.833Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/2-1.jpg?v=2024-06-25T07%3A45%3A48.833Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/2-1.jpg?v=2024-06-25T07%3A45%3A48.833Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/2-1.jpg?v=2024-06-25T07%3A45%3A48.833Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/2-1.jpg?v=2024-06-25T07%3A45%3A48.833Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/2-1.jpg?v=2024-06-25T07%3A45%3A48.833Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="--dark">
						Обслуговування коліс: Експертний догляд, щоб ваші колеса були справними та плавно рухалися.{"\n"}
						<br />
						{" "}Вирівнювання колеса: Регулювання спиць для усунення хитання та забезпечення прямого колеса.{"\n"}
						<br />
						{" "}Капітальний ремонт втулок: Очищення, перевірка та змащення втулок коліс для плавного обертання.{"\n"}
						<br />
						{" "}Заміна спиць: заміна пошкоджених або зламаних спиць для збереження цілісності колеса.{"\n"}
						<br />
						{" "}Заміна шин і камер:  Встановіть нові шини або камери, щоб ваша поїздка продовжувала рухатися.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/66795e813f24780021f8255f/images/1-3.jpg?v=2024-06-25T07:45:48.815Z"
						display="block"
						margin="0px 0px 20px 0px"
						max-width="100%"
						max-height="300px"
						object-fit="cover"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/1-3.jpg?v=2024-06-25T07%3A45%3A48.815Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/1-3.jpg?v=2024-06-25T07%3A45%3A48.815Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/1-3.jpg?v=2024-06-25T07%3A45%3A48.815Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/1-3.jpg?v=2024-06-25T07%3A45%3A48.815Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/1-3.jpg?v=2024-06-25T07%3A45%3A48.815Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/1-3.jpg?v=2024-06-25T07%3A45%3A48.815Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/1-3.jpg?v=2024-06-25T07%3A45%3A48.815Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="--dark">
						Обслуговування гальм:  Переконайтеся, що ваші гальма чутливі та надійні.{"\n"}
						<br />
						{" "}Заміна гальмівних колодок: встановлення нових гальмівних колодок для покращення гальмівної сили.{"\n"}
						<br />
						{" "}Прокачування гідравлічних гальм:  Видалення бульбашок повітря з гідравлічних гальмівних систем для стабільної роботи.{"\n"}
						<br />
						{" "}Заміна кабелю: Заміна зношених або потертих гальмівних кабелів для відновлення ефективності гальмування.{"\n"}
						<br />
						{" "}Регулювання супорта: Точне налаштування гальмівних супортів для оптимального контакту та контролю.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="0 0 100px 0" quarkly-title="List-2">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="0 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/66795e813f24780021f8255f/images/2-2.jpg?v=2024-06-25T07:45:48.813Z"
						display="block"
						margin="0px 0px 20px 0px"
						max-width="100%"
						max-height="300px"
						width="100%"
						height="100%"
						object-fit="cover"
						srcSet="https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/2-2.jpg?v=2024-06-25T07%3A45%3A48.813Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/2-2.jpg?v=2024-06-25T07%3A45%3A48.813Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/2-2.jpg?v=2024-06-25T07%3A45%3A48.813Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/2-2.jpg?v=2024-06-25T07%3A45%3A48.813Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/2-2.jpg?v=2024-06-25T07%3A45%3A48.813Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/2-2.jpg?v=2024-06-25T07%3A45%3A48.813Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66795e813f24780021f8255f/images/2-2.jpg?v=2024-06-25T07%3A45%3A48.813Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="--dark">
						Послуги трансмісії: Підтримання вашої трансмісії чистою та ефективною.{"\n"}
						<br />
						{" "}Очищення ланцюга: Видалення бруду та бруду з ланцюга, щоб подовжити термін його служби.{"\n"}
						<br />
						{" "}Заміна касети: Заміна зношених касет для більш плавного перемикання передач.{"\n"}
						<br />
						{" "}Регулювання перемикачів: Забезпечення точного вирівнювання та плавної роботи перемикачів.{"\n"}
						<br />
						{" "}Технічне обслуговування шатунів: Перевірка та обслуговування шатунів для оптимальної передачі потужності.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 20px 0px" font="--headline3">
						<Em>
							Щоб дізнатися більше про весь спектр наших послуг і про те, як ми можемо допомогти підтримувати ваш велосипед у відмінному стані, зв’яжіться з нами або відвідайте наш магазин. Наш привітний персонал завжди готовий допомогти вам і надати детальну інформацію про наші пропозиції.
Ми запрошуємо вас відчути різницю Wheel Wizards. Наша прихильність до якісного обслуговування та задоволеності клієнтів гарантує, що ви та ваш велосипед отримаєте найкращий догляд.
						</Em>
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text
				as="h2"
				font="--headline1"
				md-font="--headline2"
				margin="20px 0 0 0"
				text-align="center"
			>
				Їдьте з упевненістю
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				У Wheel Wizards Bicycle Repair Shop ми прагнемо, щоб ви та ваш велосипед рухалися без проблем. Довіртеся нам як спеціалістам з ремонту велосипедів, і дозвольте нам гарантувати, що кожна поїздка буде ідеальною.
				<br />
				<br />
				Ми з нетерпінням чекаємо зустрічі з вами найближчим часом і допоможемо вам насолоджуватися ще багатьма милями щасливої ​​їзди на велосипеді!
			</Text>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b7ae60009abe001f027d1c"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});